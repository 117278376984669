import React from "react"
import { graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Menu from '../components/menu'
import PostsGrid from '../components/posts-grid'
import Footer from '../components/footer'
import Comments from '../components/comments'
import Seo from '../components/seo'
import FeaturedImage from '../components/featured-image'
import FormattedText from '../components/formatted-text'
import PostIdentity from '../components/post-identity'
import AdSense from 'react-adsense'


import {siteUrl, adClient, adSlotInArticle} from '../utils/config'
import {getRandomFromArray,getRelatedFromArray} from '../utils/utils'

const Post = ({ data }) => {
    const post = data.strapiPosts
    const allposts = data.allStrapiPosts.edges
    const allStories = data.allStrapiStories.edges
    const postAndStories = allposts.concat(allStories)
    const tags = post.tags
    const category = post.category.slug
    const relatedPosts = getRelatedFromArray(category, tags, postAndStories)
    const morePosts = getRandomFromArray(relatedPosts, 3)
    const seo = {
      metaTitle: post.title,
      metaDescription: "",
      shareImage: post.image,
      article: true,
    }
    let allPageViews = 0
    data.allPageViews.nodes.forEach((page)=>{
      allPageViews += page.totalCount
    })
    
    return (
    <>
    <Seo seo ={seo}/>
    <Container fluid="md">
        <Row>
        <Col>
            <Menu/>
        </Col>
        </Row>
        <Row id ="content-row">
        <Col>
        <Row>
        <FeaturedImage alt={post.title || ""} image={post.image || ""} post ={true} />
        </Row>
        <FormattedText title= {post.title || ""} content={post.content || ""} allowHTML={true} readerMenu={true} pageViews={allPageViews}/>
        <PostIdentity date={post.date} author={post.author} category={post.category} tags={post.tags}/>
        <AdSense.Google
          client={adClient}
          slot={adSlotInArticle}
        />
        <Comments url={`${siteUrl}/posts/${post.slug}`} slug={`posts-${post.slug}`} title={post.title}/>
        </Col>
        </Row>
        <Row id="more-posts-row">
        <h4>Entradas relacionadas</h4>
        </Row>
        <Row>
        <PostsGrid posts={morePosts} />
        </Row>
        <Footer/>
    </Container>
    </>

            
    )
}

/*

 */

export const query = graphql`
query PostsQuery($slug: String!, $regexSlug: String!){
    strapiPosts (slug: {eq: $slug}){
        title
        slug
        date
        content
        image {
            localFile {
              publicURL
              childImageSharp {
                  gatsbyImageData(height: 300)
              }
            }
        }
        author {
            name
            slug
            donation
        }
        category {
            name
            slug
        }
        tags {
            name
            slug
        }
    }
    allStrapiPosts (filter:{slug:{ne: $slug}}, sort: {fields: date, order: DESC}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
      }
      allStrapiStories (filter:{slug:{ne: $slug} }, sort: {fields: date, order: DESC}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
      }
    allPageViews(filter: {id: {regex: $regexSlug}}) {
      nodes {
        totalCount
      }
    }
  }
`

export default Post